import React from 'react';
import {ThemeProvider} from 'styled-components';

import themes from '../commonStyles/themes';
import { MainContainer, ThemeContainer } from '../commonStyles/Containers';

import AllFixedElements from '../components/AllFixedElements';

import SEO from '../components/seo';

import SectionInfoCaseStudy from '../componentsCaseStudy/SectionInfoCaseStudy';
import SectionImageCaseStudy from '../componentsCaseStudy/SectionImageCaseStudy';
import SectionDescCaseStudy  from '../componentsCaseStudy/SectionDescCaseStudy';
import SectionPaletteCaseStudy from '../componentsCaseStudy/SectionPaletteCaseStudy';
import SectionImagesTiles from '../componentsCaseStudy/SectionImagesTiles';
import SectionLastCaseStudy from '../componentsCaseStudy/SectionLastCaseStudy'

import SectionFooter from '../components/SectionFooter';
import GlobalStyle from '../components/layoutCss';

import MalvaContent from '../data/MalvaContent';

class MalvaCaseStudy extends React.PureComponent {

  render() {
    return (
      <>
        <GlobalStyle />
        <ThemeProvider theme={themes.themeMain}>
          <ThemeContainer>
            <>
              <SEO
                title='Malva Gems'
                path={this.props.path}
              />
              <AllFixedElements
                isCaseStudy={true}
              />
              <MainContainer>
                  <SectionInfoCaseStudy
                    topdesc1={MalvaContent.topdesc1}
                    topdesc2={MalvaContent.topdesc2}
                    projectName={MalvaContent.projectName}
                    projectCategories1={MalvaContent.projectCategories1}
                    projectCategories2={MalvaContent.projectCategories2}
                    projectDeliverables1={MalvaContent.projectDeliverables1}
                    projectDeliverables2 ={MalvaContent.projectDeliverables2}
                  />
                  <SectionImageCaseStudy bgImg={MalvaContent.HeadImage}/>

                  {/*----------------------------Logo----------------------------*/}

                  <SectionDescCaseStudy
                    descCounter ={'01'}
                    descHeader={MalvaContent.descHeader1}
                    descParagraph={MalvaContent.descParagraph1}
                  />
                  <SectionDescCaseStudy
                    descCounter ={'02'}
                    descHeader={MalvaContent.descHeader2}
                    descParagraph={MalvaContent.descParagraph2}
                  />
                  <SectionPaletteCaseStudy myColorPalette={MalvaContent.colorPalette}/>
                  <SectionImageCaseStudy bgImg={MalvaContent.ImageLogo} />
                  <SectionImageCaseStudy bgImg={MalvaContent.ImageIcons} />


                  {/*----------------------------Identity----------------------------*/}

                  <SectionDescCaseStudy
                    descCounter ={'03'}
                    descHeader={MalvaContent.descHeader3}
                    descParagraph={MalvaContent.descParagraph3}
                  />
                  <SectionImageCaseStudy bgImg={MalvaContent.ImageBranding} />
                  <SectionImageCaseStudy bgImg={MalvaContent.ImageBranding2} />
                  <SectionImageCaseStudy bgImg={MalvaContent.ImageBranding3} />
                  <SectionImagesTiles myArray={MalvaContent.GalleryBranding}/>
                  <SectionLastCaseStudy />
                  <SectionFooter />
              </MainContainer>
            </>
          </ThemeContainer>
        </ThemeProvider>
      </>
    );
  }
}

export default MalvaCaseStudy;
