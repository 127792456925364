import icons from '../images/projects/malva/icons.png';
import logo from '../images/projects/malva/logo.png';
import bcards from '../images/projects/malva/bcards.png';
import malva1 from '../images/projects/malva/malva_1.jpg';
import branding from '../images/projects/malva/branding.png';
import malva3 from '../images/projects/malva/malva_3.jpg';
import malva4 from '../images/projects/malva/malva_4.jpg';

const MalvaPalette = [
  { id: 1, colorCode: '#efeae9', colorName: 'Alabaster' },
  { id: 2, colorCode: '#D8CFCF', colorName: 'Light gray' },
  { id: 3, colorCode: '#F5C8C1', colorName: 'Tea rose' },
  { id: 4, colorCode: '#D8B1A3', colorName: 'Pastel beige' },
  { id: 5, colorCode: '#8C8DA1', colorName: 'Gray blue' },
  { id: 6, colorCode: '#17253a', colorName: 'Yankees blue', isDark: true },
  { id: 7, colorCode: '#2a2a34', colorName: 'Gunmetal', isDark: true },
  { id: 8, colorCode: '#ffffff', colorName: 'White' },
];

export const GalleryMalvaBranding = [
  { id: 1, src: malva3 },
  { id: 2, src: branding }
];


export default {
  colorPalette: MalvaPalette,
  HeadImage: malva4,
  projectName: 'Malva Gems',
  projectCategories1:'Visual identity',
  projectCategories2:'Art direction',
  projectDeliverables1:'Naming, Brand identity elements, photos',
  projectDeliverables2:'Online and offline marketing materials',
  topdesc1: 'Malva Gems is a small jewelry studio, based in Poland. ',
  topdesc2: 'They specialize in handcrafted pieces made from high-quality natural gemstones and sterling silver.',
  descHeader1:'What we did',
  descParagraph1: 'Our role was to develop an elegant but simple visual identity, that will appeal to the audience. The keywords: Natural(but not raw), feminine and classy (but not luxurious). ',
  descHeader2:'',
  descParagraph2:'After some market research and discussing our ideas with the owner, we’ve decided on neutral, elegant colour palette, consisting of soft pastels and faded-out whites, contrasting with rose-gold accents and grey-blue elements. Next, we have created the logo. It combines two elements – a modern interpretation of Malva flower and cylinder – simplified shape of a gemstone. There are also some icons, representing different categories in the shop.',
  ImageLogo: logo,
  ImageIcons: icons,
  descHeader3:'',
  descParagraph3: 'From beautiful stationery, through discount cards, packaging, thank you letters,to unique packaging and invoices – we have designed it all. Did we mention social media posts templates and product photoshoot? Yes, we did that too.',
  ImageBranding: malva1,
  ImageBranding2: bcards,
  ImageBranding3: malva4,
  GalleryBranding: GalleryMalvaBranding,

};
